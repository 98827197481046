@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@200;300;400;500;600;700;800;900&display=swap");

@layer base {
  *,
  a,
  input,
  button {
    font-family: "Syne", sans-serif;
    scroll-behavior: smooth;
  }
}
body {
  background-color: #fcfcfc;
}
